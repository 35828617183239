<template>
  <transition appear name="loading">
    <div
      v-if="gotSettings && checkedCookies && captchaReady"
      id="app"
      :style="{
        '--color-a': colorA,
        '--color-b': colorB,
        '--logo-url': `url('${ logo }')`
      }"
    >
      <router-view />
    </div>

    <app-loader v-else/>
  </transition>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions, mapState } from 'vuex'
import * as types from '@store/types'
import AppLoader from '@components/common/Loader'
import cookie from 'js-cookie'

export default {
  components: { AppLoader },

  data: () => ({
    checkedCookies: false
  }),

  computed: {
    ...mapGetters({
      colorA: types.SETTINGS_COLOR_A,
      colorB: types.SETTINGS_COLOR_B,
      logo: types.SETTINGS_LOGO,
      gotSettings: types.SETTINGS_GOT_PARAMETERS,
      appType: types.SETTINGS_APP_TYPE,
      redirectUrl: types.SETTINGS_REDIRECT,
      apiUrl: types.SETTINGS_API,
      loginType: types.SETTINGS_LOGIN_TYPE
    }),

    ...mapState({
      captchaToken: state => (state.login || {}).captchaToken
    }),

    captchaReady () {
      const { captchaToken, loginType } = this

      switch (loginType) {
        case 'jwt':
          return !!captchaToken

        default:
          return !!loginType
      }
    }
  },

  methods: {
    ...mapActions({
      getParameters: types.SETTINGS_GET_PARAMETERS
    }),

    async checkCookies () {
      if (await this.redirectToApp())
        window.location.href = this.redirectUrl
      else
        this.checkedCookies = true
    },

    async redirectToApp () {
      const domain = process.env.VUE_APP_COOKIE_DOMAIN

      const token = {
        rh: cookie.get('token', { domain }),
        bpo: cookie.get('access_token', { domain })
      }[this.appType]

      const ciSession = {
        rh: cookie.get('cisession', { domain }),
        bpo: true
      }[this.appType]

      if (!token || !ciSession) return false

      const url = `${this.apiUrl}/api/v1/is-authenticated`
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }

      try {
        await axios.request({ url, headers })
        return true
      } catch { return false }
    }
  },

  async created () {
    await this.getParameters()
    await this.checkCookies()
  }
}
</script>
